import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      advantage_1: file(relativePath: { eq: "advantage_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantage_2: file(relativePath: { eq: "advantage_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantage_3: file(relativePath: { eq: "advantage_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantage_4: file(relativePath: { eq: "advantage_4.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantage_5: file(relativePath: { eq: "advantage_5.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantageW_1: file(relativePath: { eq: "advantageW_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantageW_2: file(relativePath: { eq: "advantageW_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantageW_3: file(relativePath: { eq: "advantageW_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantageW_4: file(relativePath: { eq: "advantageW_4.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      advantageW_5: file(relativePath: { eq: "advantageW_5.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div
      css={css`
        text-align: center;
        font-family: PingFang-SC-Bold, PingFang-SC;
      `}
      className="dataAnalysis-advantage"
    >
      <Container>
        <div>
          <div className="dataAnalysis-advantage-title">核心优势</div>
          <Row className="dataAnalysis-advantage-content">
            <Col
              xl="5"
              lg="5"
              md="12"
              sm="12"
              xs="12"
              css={css`
            :hover {
              .dataAnalysis-advantage-step{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                    div.gatsby-image-wrapper{ 
                      opacity:0
                    }
                }
          `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                  border-radius: 120px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `}
                className="dataAnalysis-advantage-step"
              >
                <div
                  css={css`
                    background: url(${data.advantageW_1.childImageSharp.fluid
                    .src})
                      center center;
                    background-size: cover;
                  `}
                  className="dataAnalysis-advantage-step-img"
                  >
                  <Img
                   fluid={data.advantage_1.childImageSharp.fluid}
                   alt=""
                   css={css`
                     width: 100%;
                   `}
                 />
               </div>
              </div>
              <div
                css={css`
                  font-weight: 400;
                `}
                className="dataAnalysis-advantage-step-text"
              >
                数据整合能力
              </div>
            </Col>
            <Col
              xl="5"
              lg="5"
              md="12"
              sm="12"
              xs="12"
              css={css`
            :hover {
              .dataAnalysis-advantage-step{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                    div.gatsby-image-wrapper{ 
                      opacity:0
                    }
                }
          `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                  border-radius: 120px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `}
                className="dataAnalysis-advantage-step"
              >
                <div
                  css={css`
                    background: url(${data.advantageW_2.childImageSharp.fluid
                    .src})
                      center center;
                    background-size: cover;
                  `}
                  className="dataAnalysis-advantage-step-img"
                >
                   <Img
                    fluid={data.advantage_2.childImageSharp.fluid}
                    alt=""
                    css={css`
                      width: 100%;
                    `}
                  />
                </div>
              </div>
              <div
                css={css`
                  font-weight: 400;
                `}
                className="dataAnalysis-advantage-step-text"
              >
                数据处理能力
              </div>
            </Col>
            <Col
              xl="5"
              lg="5"
              md="8"
              sm="8"
              xs="8"
              css={css`
            :hover {
              .dataAnalysis-advantage-step{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                    div.gatsby-image-wrapper{ 
                      opacity:0
                    }
                }
          `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                  border-radius: 120px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `}
                className="dataAnalysis-advantage-step"
              >
                <div
                  css={css`
                    background: url(${data.advantageW_3.childImageSharp.fluid
                    .src})
                      center center;
                    background-size: cover;
                  `}
                  className="dataAnalysis-advantage-step-img"
                  >
                  <Img
                   fluid={data.advantage_3.childImageSharp.fluid}
                   alt=""
                   css={css`
                     width: 100%;
                   `}
                 />
               </div>
              </div>
              <div
                css={css`
                  font-weight: 400;
                `}
                className="dataAnalysis-advantage-step-text"
              >
                数据质量有保证
              </div>
            </Col>
            <Col
              xl="5"
              lg="5"
              md="8"
              sm="8"
              xs="8"
              css={css`
            :hover {
              .dataAnalysis-advantage-step{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                    div.gatsby-image-wrapper{ 
                      opacity:0
                    }
                }
          `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                  border-radius: 120px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `}
                className="dataAnalysis-advantage-step"
              >
                <div
                  css={css`
                    background: url(${data.advantageW_4.childImageSharp.fluid
                    .src})
                      center center;
                    background-size: cover;
                  `}
                  className="dataAnalysis-advantage-step-img"
                  >
                  <Img
                   fluid={data.advantage_4.childImageSharp.fluid}
                   alt=""
                   css={css`
                     width: 100%;
                   `}
                 />
               </div>
              </div>
              <div
                css={css`
                  font-weight: 400;
                `}
                className="dataAnalysis-advantage-step-text"
              >
                专业化行业模型
              </div>
            </Col>
            <Col
              xl="4"
              lg="4"
              md="8"
              sm="8"
              xs="8"
              css={css`
            width: 100%;
            :hover {
              .dataAnalysis-advantage-step{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                    div.gatsby-image-wrapper{ 
                      opacity:0
                    }
                }
          `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                  border-radius: 120px;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `}
                className="dataAnalysis-advantage-step"
              >
                <div
                  css={css`
                    background: url(${data.advantageW_5.childImageSharp.fluid
                    .src})
                      center center;
                    background-size: cover;
                  `}
                  className="dataAnalysis-advantage-step-img"
                  >
                  <Img
                   fluid={data.advantage_5.childImageSharp.fluid}
                   alt=""
                   css={css`
                     width: 100%;
                   `}
                 />
               </div>
              </div>
              <div
                css={css`
                  font-weight: 400;
                `}
                className="dataAnalysis-advantage-step-text"
              >
                实时监控
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
