import React from 'react'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'

export default () => (
  <div className="webConst-services">
    <Container>
      <div
        className="webConst-services-title"
        css={css`
          text-align: center;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
        `}
      >
        服务范围
      </div>
      <Row className="webConst-services-content">
        <Col
          xl="8"
          lg="8"
          md="24"
          sm="24"
          xs="24"
          css={css`
        
            :hover {
                .webConst-services-step-num{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                }
          `}
          className="webConst-services-step"
        >
          <Col xl="24" lg="24" md="6" sm="6" xs="6">
            <div
              css={css`
                box-shadow: 0px 0px 30px 15px rgba(235, 235, 235, 0.5);
                font-family: Brig;
                color: #c3ccd6;
                text-align: center;
                margin: 0 auto;
              `}
              className="webConst-services-step-num"
            >
              01
            </div>
          </Col>
          <Col
            xl="24"
            lg="24"
            md="18"
            sm="18"
            xs="18"
            className="webConst-services-step-content"
          >
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                text-align: center;
              `}
              className="webConst-services-step-subtitle"
            >
              APP开发
            </div>
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(151, 152, 153, 1);
              `}
              className="webConst-services-step-text"
            >
              传注于Android/I0S的客户端开发，为电商领域的业务运营和店铺维护，提供专业的解决方案。
            </div>
          </Col>
        </Col>
        <Col
          xl="8"
          lg="8"
          md="24"
          sm="24"
          xs="24"
          css={css`
        
            :hover {
                .webConst-services-step-num{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                }
          `}
          className="webConst-services-step"
        >
          <Col xl="24" lg="24" md="6" sm="6" xs="6">
            <div
              css={css`
                box-shadow: 0px 0px 30px 15px rgba(235, 235, 235, 0.5);
                font-family: Brig;
                color: #c3ccd6;
                text-align: center;
                margin: 0 auto;
              `}
              className="webConst-services-step-num"
            >
              02
            </div>
          </Col>
          <Col
            xl="24"
            lg="24"
            md="18"
            sm="18"
            xs="18"
            className="webConst-services-step-content"
          >
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                text-align: center;
              `}
              className="webConst-services-step-subtitle"
            >
              ERP系统
            </div>
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(151, 152, 153, 1);
              `}
              className="webConst-services-step-text"
            >
              定制化的电商ERP系统、对接主流二十多家出口跨境电商平台，以帮助跨境卖家提高工作效率为目标，为跨境卖家提供订单处理、库存管理、客户管理等功能。
            </div>
          </Col>
        </Col>
        <Col
          xl="8"
          lg="8"
          md="24"
          sm="24"
          xs="24"
          css={css`
        
            :hover {
                .webConst-services-step-num{
                    background: linear-gradient( 180deg,rgba(246,179,82,1) 0%,rgba(255,147,115,1) 100% );
                    color: rgba(248,249,250,1);
                }
          `}
          className="webConst-services-step"
        >
          <Col xl="24" lg="24" md="6" sm="6" xs="6">
            <div
              css={css`
                box-shadow: 0px 0px 30px 15px rgba(235, 235, 235, 0.5);
                font-family: Brig;
                color: #c3ccd6;
                text-align: center;
                margin: 0 auto;
              `}
              className="webConst-services-step-num"
            >
              03
            </div>
          </Col>
          <Col
            xl="24"
            lg="24"
            md="18"
            sm="18"
            xs="18"
            className="webConst-services-step-content"
          >
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                text-align: center;
              `}
              className="webConst-services-step-subtitle"
            >
              智能分析系统
            </div>
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(151, 152, 153, 1);
                line-height: 20px;
                margin-top: 31px;
              `}
              className="webConst-services-step-text"
            >
              定制化的商业智能中心，BI系统,是一个数据系统，从各种维度统计分析当前电商网站的运行状况，为经营决策者提供更好的经营管理环境和决策支持，为公司创造更大的价值。
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  </div>
)
