import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      relatedIndustry_1: file(relativePath: { eq: "relatedIndustry_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      relatedIndustry_2: file(relativePath: { eq: "relatedIndustry_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      relatedIndustry_3: file(relativePath: { eq: "relatedIndustry_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div className="webConst-relatedI">
      <div
        css={css`
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          width: 100%;
          text-align: center;
          background: rgba(251, 162, 99, 1);
          color: rgba(255, 255, 255, 1);
        `}
        className="webConst-relatedI-title"
      >
        涉及行业
      </div>
      <Container
        css={css`
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
          margin: 0 auto;
        `}
        className="webConst-relatedI-content"
      >
        <Row>
          <Col xl="8" lg="8" md="8" sm="8" xs="8">
            <Img
              fluid={data.relatedIndustry_1.childImageSharp.fluid}
              alt=""
              css={css`
                margin: 0 auto;
                width: 80%;
              `}
            />
            <div
              css={css`
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(85, 85, 85, 1);
                text-align: center;
              `}
              className="webConst-relatedI-text"
            >
              奢侈品
            </div>
          </Col>

          <Col xl="8" lg="8" md="8" sm="8" xs="8">
            <Img
              fluid={data.relatedIndustry_2.childImageSharp.fluid}
              alt=""
              css={css`
                margin: 0 auto;
                width: 80%;
              `}
            />
             <div
              css={css`
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(85, 85, 85, 1);
                text-align: center;
              `}
              className="webConst-relatedI-text"
            >
              化妆品
            </div>
          </Col>

          <Col xl="8" lg="8" md="8" sm="8" xs="8">
            <Img
              fluid={data.relatedIndustry_3.childImageSharp.fluid}
              alt=""
              css={css`
                margin: 0 auto;
                width: 80%;
              `}
            />
            <div
              css={css`
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: rgba(85, 85, 85, 1);
                text-align: center;
              `}
              className="webConst-relatedI-text"
            >
              服饰衣服
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
