import React from 'react'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'

export default () => (
  <div className="note-process">
    <Container
      css={css`
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        text-align: center;
        position: relative;
      `}
      className="note-process-title"
    >
      服务流程
      <Row
        css={css`
          display: flex;
          margin: 0 auto;
          width: 90%;
        `}
        className="note-process-content"
      >
        <Col
          xl="5"
          lg="5"
          md="8"
          sm="8"
          xs="8"
          css={css`
            position: relative;
            :hover {
              div:nth-of-type(1) {
                background: #f77920;
              }
              div:nth-of-type(2) {
                color: #f77920;
                opacity: 0.1;
              }
              div:nth-of-type(3) {
                color: rgba(178, 187, 205, 1);
                margin-top: -30px !important;
              }
              div:nth-of-type(4) {
                display: block;
              }
            }
          `}
          className="note-process-step"
        >
          <div
            css={css`
              background: rgba(178, 189, 206, 1);
            `}
            className="note-process-step-dot"
          />
          <div
            css={css`
              font-family: Brig;
              color: rgba(243, 243, 243, 1);
              text-align: left;
            `}
            className="note-process-step-serial"
          >
            01
          </div>
          <div
            css={css`
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(51, 51, 51, 1);
              text-align: left;
            `}
            className="note-process-step-subtitle"
          >
            认证
          </div>
          <div
            css={css`
              border: 3px solid #f77920;
              position: absolute;
              display: none;
            `}
            className="note-process-step-circle"
          />
        </Col>
        <Col
          xl="5"
          lg="5"
          md="8"
          sm="8"
          xs="8"
          css={css`
            position: relative;
            :hover {
              div:nth-of-type(1) {
                background: #f77920;
              }
              div:nth-of-type(2) {
                color: #f77920;
                opacity: 0.1;
              }
              div:nth-of-type(3) {
                color: rgba(178, 187, 205, 1);
                margin-top: -30px !important;
              }
              div:nth-of-type(4) {
                display: block;
              }
            }
          `}
          className="note-process-step"
        >
          <div
            css={css`
              background: rgba(178, 189, 206, 1);
            `}
            className="note-process-step-dot"
          />
          <div
            css={css`
              font-family: Brig;
              color: rgba(243, 243, 243, 1);
              text-align: left;
            `}
            className="note-process-step-serial"
          >
            02
          </div>
          <div
            css={css`
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(51, 51, 51, 1);
              text-align: left;
            `}
            className="note-process-step-subtitle"
          >
            审核
          </div>
          <div
            css={css`
              border: 3px solid #f77920;
              position: absolute;
              display: none;
            `}
            className="note-process-step-circle"
          />
        </Col>
        <Col
          xl="5"
          lg="5"
          md="8"
          sm="8"
          xs="8"
          css={css`
            position: relative;
            :hover {
              div:nth-of-type(1) {
                background: #f77920;
              }
              div:nth-of-type(2) {
                color: #f77920;
                opacity: 0.1;
              }
              div:nth-of-type(3) {
                color: rgba(178, 187, 205, 1);
                margin-top: -30px !important;
              }
              div:nth-of-type(4) {
                display: block;
              }
            }
          `}
          className="note-process-step"
        >
          <div
            css={css`
              background: rgba(178, 189, 206, 1);
            `}
            className="note-process-step-dot"
          />
          <div
            css={css`
              font-family: Brig;
              color: rgba(243, 243, 243, 1);
              text-align: left;
            `}
            className="note-process-step-serial"
          >
            03
          </div>
          <div
            css={css`
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(51, 51, 51, 1);
              text-align: left;
              text-align: left;
            `}
            className="note-process-step-subtitle"
          >
            风控
          </div>
          <div
            css={css`
              border: 3px solid #f77920;
              position: absolute;
              display: none;
            `}
            className="note-process-step-circle"
          />
        </Col>
        <Col
          xl="5"
          lg="5"
          md="8"
          sm="8"
          xs="8"
          css={css`
            position: relative;
            :hover {
              div:nth-of-type(1) {
                background: #f77920;
              }
              div:nth-of-type(2) {
                color: #f77920;
                opacity: 0.1;
              }
              div:nth-of-type(3) {
                color: rgba(178, 187, 205, 1);
                margin-top: -30px !important;
              }
              div:nth-of-type(4) {
                display: block;
              }
            }
          `}
          className="note-process-step"
        >
          <div
            css={css`
              background: rgba(178, 189, 206, 1);
            `}
            className="note-process-step-dot"
          />
          <div
            css={css`
              font-family: Brig;
              color: rgba(243, 243, 243, 1);
              text-align: left;
            `}
            className="note-process-step-serial"
          >
            04
          </div>
          <div
            css={css`
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(51, 51, 51, 1);
              text-align: left;
            `}
            className="note-process-step-subtitle"
          >
            客服
          </div>
          <div
            css={css`
              border: 3px solid #f77920;
              position: absolute;
              display: none;
            `}
            className="note-process-step-circle"
          />
        </Col>
        <Col
          xl="4"
          lg="4"
          md="8"
          sm="8"
          xs="8"
          css={css`
            position: relative;
            :hover {
              div:nth-of-type(1) {
                background: #f77920;
              }
              div:nth-of-type(2) {
                color: #f77920;
                opacity: 0.1;
              }
              div:nth-of-type(3) {
                color: rgba(178, 187, 205, 1);
                margin-top: -30px !important;
              }
              div:nth-of-type(4) {
                display: block;
              }
            }
          `}
          className="note-process-step"
        >
          <div
            css={css`
              background: rgba(178, 189, 206, 1);
            `}
            className="note-process-step-dot"
          />
          <div
            css={css`
              font-family: Brig;
              color: rgba(243, 243, 243, 1);
              text-align: left;
            `}
            className="note-process-step-serial"
          >
            05
          </div>
          <div
            css={css`
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: rgba(51, 51, 51, 1);
              text-align: left;
            `}
            className="note-process-step-subtitle"
          >
            四位一体
          </div>
          <div
            css={css`
              border: 3px solid #f77920;
              position: absolute;
              display: none;
            `}
            className="note-process-step-circle"
          />
        </Col>
      </Row>
      <div
        css={css`
          background-color: rgba(243, 243, 243, 1);
          margin: 0 auto;
          position: absolute;
          height: 1.2px;
          z-index: -1;
        `}
        className="note-process-tieku-1"
      />
      <div
        css={css`
          background-color: rgba(243, 243, 243, 1);
          margin: 0 auto;
          position: absolute;
          height: 1.2px;
          z-index: -1;
        `}
        className="note-process-tieku-2"
      />
    </Container>
  </div>
)
