import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      oval: file(relativePath: { eq: "oval.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div className="dataAnalysis-process">
      <Container
        css={css`
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          text-align: center;
          position: relative;
        `}
        className="dataAnalysis-process-title"
      >
        业务流程
        <Row
          css={css`
            position: relative;
          `}
          className="dataAnalysis-process-content"
        >
          <div
            css={css`display: flex;
                width: 100%;
                }`}
          >
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="9"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                01
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                客户提出需求
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="9"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                02
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                数据采集
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="6"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                03
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                数据存储
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
          </div>
          <div
            css={css`display: flex;
                width: 100%;
                }`}
            className="dataAnalysis-process-content-reverse"
          >
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="6"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                06
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                数据验收
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="9"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                05
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                数据传输
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
            <Col
              xl="8"
              lg="8"
              md="8"
              sm="8"
              xs="9"
              css={css`
                position: relative;
                :hover {
                  div:nth-of-type(1) {
                    background: #f77920;
                  }
                  div:nth-of-type(2) {
                    opacity: 0.1;
                    color: #f77920;
                  }
                  div:nth-of-type(3) {
                    color: rgba(178, 187, 205, 1);
                    margin-top: -30px !important;
                  }
                  div:nth-of-type(4) {
                    display: block;
                  }
                }
              `}
              className="dataAnalysis-process-step"
            >
              <div
                css={css`
                  background: rgba(178, 189, 206, 1);
                `}
                className="dataAnalysis-process-step-dot"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(243, 243, 243, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-serial"
              >
                04
              </div>
              <div
                css={css`
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  text-align: left;
                `}
                className="dataAnalysis-process-step-subtitle"
              >
                数据加工
              </div>
              <div
                css={css`
                  border: 3px solid #f77920;
                  position: absolute;
                  display: none;
                `}
                className="dataAnalysis-process-step-circle"
              />
            </Col>
          </div>
          <div
            css={css`
              position: absolute;
              z-index: -1;
              background: url(${data.oval.childImageSharp.fluid.src}) center
                center;
            `}
            className="dataAnalysis-process-tieku-circle"
          />
          <div
            css={css`
              height: 1px;
              background-color: rgba(243, 243, 243, 1);
              margin: 0 auto;
              position: absolute;
              z-index: -1;
            `}
            className="dataAnalysis-process-tieku-1"
          />
          <div
            css={css`
              height: 1px;
              background-color: rgba(243, 243, 243, 1);
              margin: 0 auto;
              position: absolute;
              z-index: -1;
            `}
            className="dataAnalysis-process-tieku-2"
          />
        </Row>
      </Container>
    </div>
  )
}
