import React from 'react'
import BannerAnim, { Element } from 'rc-banner-anim'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import TweenOne from 'rc-tween-one'
import Img from 'gatsby-image'
import 'rc-banner-anim/assets/index.css'
import { Container } from 'react-bootstrap'

export default function mobileBanner() {
  const { BgElement } = Element
  const data = useStaticQuery(graphql`
    query {
      details_1_1: file(relativePath: { eq: "details_1_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      details_1_2: file(relativePath: { eq: "details_1_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div className="mobileNote">
      <Container>
        <BannerAnim>
          <Element prefixCls="banner-user-elem" key="0">
            <BgElement key="bg" className="bg">
              <Img
                fluid={data.details_1_1.childImageSharp.fluid}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
            </BgElement>
            <TweenOne
              className="banner-user-title"
              animation={{ y: 30, opacity: 0, type: 'from' }}
              css={css`
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
              `}
            >
              短信服务验证
            </TweenOne>
            <TweenOne
              className="banner-user-text"
              animation={{ y: 30, opacity: 0, type: 'from' }}
              css={css`
                font-family: PingFangSC-Medium, PingFang SC;
              `}
            >
              短信服务为用户提供的一种通信服务的能力。
              支持国内和国际快速发送验证码、短信通知和推广短信，服务范围覆盖全球200多个国家和地区。
              国内短信支持三网合一专属通道，与工信部携号转网平台实时互联。电信级运维保障，实时监控自动切换，
              到达率高达99%。完美支撑双11期间20亿短信发送，6亿用户触达。
            </TweenOne>
          </Element>

          <Element prefixCls="banner-user-elem" key="1">
            <BgElement
              key="bg"
              className="bg"
              css={css`
                box-shadow: 0px 1px 10px 1px rgba(232, 222, 220, 1);
                border-radius: 20px;
              `}
            >
              <Img
                fluid={data.details_1_2.childImageSharp.fluid}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
            </BgElement>
            <TweenOne
              className="banner-user-title"
              animation={{ y: 30, opacity: 0, type: 'from' }}
              css={css`
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
              `}
            >
              对公验证服务
            </TweenOne>
            <TweenOne
              className="banner-user-text"
              animation={{ y: 30, opacity: 0, type: 'from' }}
              css={css`
                font-family: PingFangSC-Medium, PingFang SC;
              `}
            >
              随机打款验证企业银行卡账号和企业名称信息是否一致，支持所有银行。支持所有企业对公银行卡账户验证，准确率100%。
            </TweenOne>
          </Element>
        </BannerAnim>
      </Container>
    </div>
  )
}
