import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      scenario_1: file(relativePath: { eq: "scenario_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      scenario_2: file(relativePath: { eq: "scenario_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      scenario_3: file(relativePath: { eq: "scenario_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div className="dataAnalysis-scenario">
      <div
        css={css`
          font-weight: bold;
          font-family: PingFang-SC-Bold, PingFang-SC;
          text-align: center;
          background: rgba(251, 162, 99, 1);
          color: rgba(255, 255, 255, 1);
        `}
        className="dataAnalysis-scenario-title"
      >
        应用场景
      </div>
      <Container className="dataAnalysis-scenario-content">
        <Row>
          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            className="dataAnalysis-scenario-step"
          >
            <div
              css={css`
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                border-radius: 27px;
              `}
              className="dataAnalysis-scenario-step-box"
            >
              <Img
                fluid={data.scenario_1.childImageSharp.fluid}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
              <div
                css={css`
                  font-weight: bold;
                  color: rgba(85, 85, 85, 1);
                `}
                className="dataAnalysis-scenario-step-subtitle"
              >
                软件开发
              </div>
              <div
                css={css`
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  color: rgba(85, 85, 85, 1);
                  width: 80%;
                `}
                className="dataAnalysis-scenario-step-text"
              >
                利用数据平台进行信息关联，通过数据挖掘技术，建立起数据之间的联系
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            className="dataAnalysis-scenario-step"
          >
            <div
              css={css`
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
                border-radius: 27px;
              `}
              className="dataAnalysis-scenario-step-box"
            >
              <Img
                fluid={data.scenario_2.childImageSharp.fluid}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
              <div
                css={css`
                  font-weight: bold;
                  color: rgba(85, 85, 85, 1);
                `}
                className="dataAnalysis-scenario-step-subtitle"
              >
                多维度分析
              </div>
              <div
                css={css`
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  color: rgba(85, 85, 85, 1);
                  width: 80%;
                `}
                className="dataAnalysis-scenario-step-text"
              >
                实现商品展示，搜索，下单，购买等一系列功能
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            className="dataAnalysis-scenario-step"
          >
            <div
              css={css`
                box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
              `}
              className="dataAnalysis-scenario-step-box"
            >
              <Img
                fluid={data.scenario_3.childImageSharp.fluid}
                alt=""
                css={css`
                  width: 100%;
                `}
              />
              <div
                css={css`
                  font-weight: bold;
                  color: rgba(85, 85, 85, 1);
                `}
                className="dataAnalysis-scenario-step-subtitle"
              >
                大数据分析
              </div>
              <div
                css={css`
                  font-family: PingFang-SC-Regular, PingFang-SC;
                  font-weight: 400;
                  color: rgba(85, 85, 85, 1);
                  width: 80%;
                `}
                className="dataAnalysis-scenario-step-text"
              >
                利用各种分析软件对大数据进行挖掘分析
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
