/* eslint no-unused-vars: 0 */
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import TweenOne from "rc-tween-one"
import { Container, Row, Col } from "react-bootstrap"
import ProcessNote from "./note/process"
import MobileNote from "./note/mobileNote"
import ProcessDataAnalysis from "./dataAnalysis/process"
import AdvantageDataAnalysis from "./dataAnalysis/advantage"
import ScenarioDataAnalysis from "./dataAnalysis/scenario"
import ServicesWebConst from "./webConst/services"
import RelatedIndustryWebConst from "./webConst/relatedIndustry"
import ProcessWebConst from "./webConst/process"
import style from "./styles.css"

export default ({ location }) => {
  const [option, setOption] = useState(1)
  const [noteOption, setNoteOption] = useState(1)
  const [left_, setLeft_] = useState("-0.1%")
  const [locationOption, setLocationOption] = useState(undefined)

  const change = e => {
    if (e !== locationOption) {
      setLocationOption(e)
    }
    if (e > option) {
      setOption(0)
      setLeft_(e === 2 ? "33%" : "67%")
    }
    if (e < option) {
      setOption(0)
      setLeft_(e === 2 ? "33%" : "-0.1%")
    }
    const changeColor = () => {
      setOption(e)
    }
    setTimeout(changeColor, 200)
  }
  useEffect(() => {
    if (location.state === null) {
      // eslint-disable-next-line no-param-reassign
      location.state = { option: 1 }
    }
    change(location.state.option)
  }, [])
  useEffect(() => {
    if (locationOption !== location.state.option) {
      change(location.state.option)
    }
  }, [location.state])

  const changeNoteOption = e => {
    setNoteOption(e)
  }
  const data = useStaticQuery(graphql`
    query {
      business_1: file(relativePath: { eq: "business_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      business_2: file(relativePath: { eq: "business_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      business_3: file(relativePath: { eq: "business_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      businessW_1: file(relativePath: { eq: "businessW_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      businessW_2: file(relativePath: { eq: "businessW_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      businessW_3: file(relativePath: { eq: "businessW_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      business_b: file(relativePath: { eq: "business_b.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      details_1_1: file(relativePath: { eq: "details_1_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      details_1_2: file(relativePath: { eq: "details_1_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      details_2: file(relativePath: { eq: "details_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      details_3: file(relativePath: { eq: "details_3.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  const businessDetails = [
    <div className="webConst">
      <Container>
        <Row className="webConst-intro">
          <Col xl="10" lg="10" md="24" sm="24" xs="24">
            <Img
              fluid={data.details_3.childImageSharp.fluid}
              alt=""
              css={css`
                box-shadow: 20px 10px 20px 10px rgba(248, 246, 247, 1);
                border-radius: 15px;
                width: 100%;
              `}
              className="webConst-intro-img"
            />
          </Col>
          <div className="webConst-intro-subhead">软件系统开发</div>
          <Col
            xl="14"
            lg="14"
            md="24"
            sm="24"
            xs="24"
            css={css`
              font-family: PingFang-SC-Bold, PingFang-SC;
              text-indent: 35px;
            `}
            className="webConst-intro-text"
          >
            每个企业都有自己独特的管理模式和工作流程，而目前市面上所有的管理系统，如OA、ERP、CRM、HRM、进销存等系统大多数都是通用软件，这种类型软件很难满足企业个性化的需求。为了满足企业个性化业务需求，让软件来适合企业管理方式，而不是让企业去适应软件的功能而使用，所以根据企业个性化管理流程和方式，量身定制一套属于自己公司管理模式的软件，已经是每个企业信息化管理实施的标准。
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们为企业提供量身定制软件开发服务，采取专业的定制开发流程来满足各大企业软件产品的不同需求。
          </Col>
        </Row>
      </Container>
      <ServicesWebConst />
      <RelatedIndustryWebConst />
      <ProcessWebConst />
    </div>,
    <div className="dataAnalysis">
      <Container>
        <Row className="dataAnalysis-intro">
          <Col xl="10" lg="10" md="24" sm="24" xs="24">
            <Img
              fluid={data.details_2.childImageSharp.fluid}
              alt=""
              css={css`
                box-shadow: 20px 10px 20px 10px rgba(248, 246, 247, 1);

                width: 100%;
              `}
              className="dataAnalysis-intro-img"
            />
          </Col>
          <div className="dataAnalysis-intro-subhead">数据采集与分析</div>
          <Col
            xl="14"
            lg="14"
            md="24"
            sm="24"
            xs="24"
            css={css`
              font-family: PingFang-SC-Bold, PingFang-SC;
              text-indent: 35px;
            `}
            className="dataAnalysis-intro-text"
          >
            拥有多年的行业经验，在各种数据查询上建立了健全完善的网络，以大数据分析为核心，基于数据处理、数据分析于一体的技术支持服务。多针对于跨境电商和国内电商的热销商品以及电商网站转化率的采集和分析，通过准确可靠的数据为客户解决需求和量身定制准确的产品。
          </Col>
        </Row>
      </Container>
      <AdvantageDataAnalysis />
      <ScenarioDataAnalysis />
      <ProcessDataAnalysis />
    </div>,
    <div className="note">
      <Container>
        <div className="webNote">
          <Row>
            {/* 左侧 */}
            <Col xl="5" lg="5" md="4" sm="4" xs="4" className="note-left">
              <div
                onClick={() => changeNoteOption(1)}
                css={css`
                  cursor: pointer;
                  outline: none;
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div>
                  <div
                    css={css`
                      font-family: Brig;
                      ${noteOption === 1
                        ? "color:#F77920;opacity: 0.1;"
                        : "color:rgba(233,239,246,1);"};
                    `}
                    className="note-left-serial"
                  >
                    01
                  </div>
                  <div
                    css={css`
                      font-family: PingFang-SC-Bold, PingFang-SC;
                      font-weight: bold;
                      ${noteOption === 1
                        ? "color:#F77920;"
                        : "color:rgba(85,85,85,1);"};
                    `}
                    className="note-left-subhead"
                  >
                    短信验证服务
                  </div>
                </div>
              </div>
              <div
                onClick={() => changeNoteOption(2)}
                css={css`
                  cursor: pointer;
                  outline: none;
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                <div>
                  <div
                    css={css`
                      font-family: Brig;
                      ${noteOption === 2
                        ? "color:#F77920;opacity: 0.1;"
                        : "color:rgba(233,239,246,1);"};
                    `}
                    className="note-left-serial"
                  >
                    02
                  </div>
                  <div
                    css={css`
                      font-family: PingFang-SC-Bold, PingFang-SC;
                      font-weight: bold;
                      ${noteOption === 2
                        ? "color:#F77920;"
                        : "color:rgba(85,85,85,1);"};
                    `}
                    className="note-left-subhead"
                  >
                    对公验证服务
                  </div>
                </div>
              </div>
            </Col>
            {/* 右侧 */}
            <Col
              xl="19"
              lg="19"
              md="20"
              sm="20"
              xs="20"
              css={css`
                background: rgba(242, 246, 252, 1);
              `}
              className="note-right"
            >
              <Row>
                <Col
                  xl="10"
                  lg="10"
                  md="10"
                  sm="10"
                  xs="10"
                  className="note-right-img"
                >
                  {noteOption === 1 ? (
                    <Img
                      fluid={data.details_1_1.childImageSharp.fluid}
                      alt=""
                      css={css`
                        width: 100%;
                      `}
                    />
                  ) : (
                    <Img
                      fluid={data.details_1_2.childImageSharp.fluid}
                      alt=""
                      css={css`
                        width: 100%;
                        z-index: 1;
                      `}
                    />
                  )}
                </Col>
                <Col
                  xl="14"
                  lg="14"
                  md="14"
                  sm="14"
                  xs="14"
                  className="note-right-content"
                >
                  <div>
                    <div
                      css={css`
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                      `}
                      className="note-right-content-title"
                    >
                      {noteOption === 1 ? "短信服务验证" : "对公验证服务"}
                    </div>
                    <div
                      css={css`
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(173, 170, 174, 1);
                        text-indent: 35px;
                      `}
                      className="note-right-content-text"
                    >
                      {noteOption === 1
                        ? `短信服务为用户提供的一种通信服务的能力。
            支持国内和国际快速发送验证码、短信通知和推广短信，服务范围覆盖全球200多个国家和地区。
            国内短信支持三网合一专属通道，与工信部携号转网平台实时互联。电信级运维保障，实时监控自动切换，
            到达率高达99%。完美支撑双11期间20亿短信发送，6亿用户触达。`
                        : "随机打款验证企业银行卡账号和企业名称信息是否一致，支持所有银行。支持所有企业对公银行卡账户验证，准确率100%。"}
                    </div>
                  </div>
                </Col>
                <div
                  css={css`
                    background: rgba(242, 246, 252, 1);
                    width: 30%;
                    position: absolute;
                    left: 70%;
                    z-index: -1;
                  `}
                  className="note-float"
                />
              </Row>
            </Col>
          </Row>
        </div>
        <MobileNote />
        <ProcessNote />
      </Container>
    </div>,
  ]
  return (
    <div className="business">
      <Container
        css={css`
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 5px 5px 0px rgba(248, 246, 247, 1);
          position: relative;
        `}
        className="business-nav"
      >
        <Row>
          <TweenOne
            animation={{ left: left_, duration: 500 }}
            css={css`
              position: absolute;
              left: -0.1%;
              width: 33.33%;
            `}
          >
            <div
              css={css`
                z-index: 1;
                background: url(${data.business_b.childImageSharp.fluid.src})
                  center center;
              `}
              className="business-slider"
            />
          </TweenOne>

          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            css={css`
              z-index: 10;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              text-align: center;
              cursor: pointer;
              outline: none;
              ${option === 1
                ? `
            color: rgba(255, 255, 255, 1);
            `
                : []};
            `}
            onClick={() => change(1)}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
            className="business-navBlock"
          >
            <Img
              fluid={
                option === 1
                  ? data.businessW_3.childImageSharp.fluid
                  : data.business_3.childImageSharp.fluid
              }
              alt=""
              className="business-navBlockImg"
            />
            <div>软件系统开发</div>
          </Col>

          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            css={css`
              z-index: 10;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              text-align: center;
              cursor: pointer;
              outline: none;
              ${option === 2
                ? `
            color: rgba(255, 255, 255, 1);
            `
                : []};
            `}
            onClick={() => change(2)}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
            className="business-navBlock"
          >
            <Img
              fluid={
                option === 2
                  ? data.businessW_2.childImageSharp.fluid
                  : data.business_2.childImageSharp.fluid
              }
              alt=""
              className="business-navBlockImg"
            />
            <div>数据采集和分析</div>
          </Col>

          <Col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            css={css`
              z-index: 10;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              text-align: center;
              cursor: pointer;
              outline: none;
              ${option === 3
                ? `
            color: rgba(255, 255, 255, 1);
            `
                : []};
            `}
            onClick={() => change(3)}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
            className="business-navBlock"
          >
            <Img
              fluid={
                option === 3
                  ? data.businessW_1.childImageSharp.fluid
                  : data.business_1.childImageSharp.fluid
              }
              alt=""
              className="business-navBlockImg"
            />
            <div>短信和银行卡身份验证</div>
          </Col>
        </Row>
      </Container>

      <div className="business-content">
        <div
          css={css`
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);

            text-align: center;
          `}
          className="business-title"
        >
          业务介绍
        </div>
        {businessDetails[option - 1]}
      </div>
    </div>
  )
}
