import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Nav from '../components/nav'
import Business from '../components/operation/business'
import Footer from '../components/footer'
import SEO from '../components/seo'

export default function Home({ location }) {
  const data = useStaticQuery(graphql`
    query {
      backgroundOperation: file(
        relativePath: { eq: "backgroundOperation.png" }
      ) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div>
      <SEO title="核心业务" description="operation" />
      {/* 背景图片 */}
      <div
        css={css`
          width: 100%;
          background: url(${data.backgroundOperation.childImageSharp.fluid.src})
            center center;
        `}
        className="operationNav"
      >
        <Nav />
        <div
          css={css`
            display: inline-block;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            line-height: 84px;
            width: 100%;
            text-align: center;
            line-height: 78px;
          `}
          className="nav-title"
        >
          核心业务
        </div>
      </div>
      {/* 核心业务 */}
      <Business location={location} />

      {/* 页脚 */}
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}
